.homepage {
  background-color: #050606;
  color: white;
  padding: 70px 100px;
  font-family: "Saira", sans-serif;
  min-height: 100vh;
}

.homepage_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 20px 0px;
}

.homepage_header__info {
  padding-bottom: 80px;
}

.homepage_header__heading {
  font-size: 52px;
  font-weight: 400;
  margin-bottom: 32px;
  word-spacing: 18px;
}

.homepage_header__description {
  font-size: 17px;
  margin-bottom: 32px;
  line-height: 27px;
}

.homepage_header__button {
  border-radius: 15px;
  padding: 9px 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
}

.homepage_header__button:hover {
  background-color: rgb(187, 187, 187);
}

.homepage_header__imgClass {
  /* width: 100%; */
  height: 100%;
  margin-top: -100px;
}

/* WHY CONTAINER */
.homepage_why__container {
  display: flex;
  gap: 80px;
  text-align: center;
  justify-content: space-between;
  padding: 40px;
  padding-right: 80px;
}
.homepage_why__header {
  font-size: 48px;
  margin-top: 22px;
  width: 100%;
  font-family: "MuseoModerno", cursive;
}

.homepage_why__description {
  font-size: 20px;
  line-height: 30px;
}

/* PRODUCT DESCRIPTION CONTAINER */

.homepage_product__container {
  margin-top: 120px;
}

.product_header {
  font-size: 74px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.45);
}

.product_description {
  background-color: rgb(10, 10, 10);
  margin: 0 -100px; /* From main container padding */
  margin-top: -5px;
  padding: 30px 125px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}

.product_description:nth-child(3) {
  flex-direction: row-reverse;
}

.product_description:nth-child(4) .product_description__img {
  height: 400px;
  width: 250px;
}

.product_description__details {
  margin-right: 10%;
  margin-top: 50px;
}

.product_description__img {
  width: 35%;
}

.product_description__details_header {
  font-size: 32px;
  margin-bottom: 30px;
}

.highlight_container {
  position: relative;
}

.highlight_container::before {
  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 55%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(0deg);
}

.highlight_container__green:before {
  background: #00ff08;
}

.highlight_container__blue:before {
  background: #009dff;
}

.highlight_container__red:before {
  background: #ff0000;
}

.product_description__details_list {
  font-size: 20px;
  margin-left: -10px;
  font-weight: 400;
  margin-bottom: 30px;
}

.product_description__details_btn {
  padding: 10px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 18px;
  transition: background-color 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.product_description__details_btn__blue:hover {
  background-color: #009dff;
}

.product_description__details_btn__red:hover {
  background-color: #ff0000;
}

.product_description__details_btn__green:hover {
  background-color: #00ff08;
}

/* HOMEPAGE FEATURE SECTION */
.feature_container {
  background-color: black;
  padding-top: 60px;
  /* height: 200px; */
}
.homepage_feature {
  background-image: url("../../images/parallax\ image.jpg");
  background-size: 100vw 100vh;
  /* object-fit: fill; */
  height: 80vh;
  margin: 0 -100px;
  margin-top: -15px;
  padding: 30px 100px;
  z-index: 10;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  /* background-size: cover; */
}

.feature_detail {
  height: 370px;
  padding: 20px 40px;
  border-top: 1.5px solid white;
  border-right: 1.5px solid white;
  border-bottom: 1.5px solid white;
}

.feature_detail:nth-child(4) {
  border-right: 0px;
}

.feature_detail_img {
  width: 150px;
  height: 180px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.feature_detail_header {
  font-weight: 400;
  margin-top: -15px;
  margin-bottom: 10px;
  font-size: 34px;
}

.feature_detail_description {
  padding: 0 18px;
}

/* VIDEO SECTION */
.video_start {
  height: 100vh;
}

.video_src {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* SLIODER  */
.homepage_team__container {
  background-color: black;

  margin: 0px -95px;
  padding: 40px 90px;
  margin-top: 80px;

  margin-bottom: -67px;
}
.slider {
  margin-top: 80px;
  background-color: black;
}

.slider_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10%;

  padding: 40px 150px;
}

.slider_item_img {
  border-radius: 600px;
  height: 200%;
  width: 200%;
}

.slider_content {
  margin-top: 50px;
  width: 250%;
  text-align: center;
}
.slider_content__name {
  font-size: 33px;
  margin-bottom: 10px;
}

.slider_content_title {
  font-weight: 400;
  font-size: 20px;
  height: 45px;
  margin-bottom: 15px;
}

.slider_content_description {
  width: 80%;
  line-height: 25px;
  font-weight: 200;
  font-size: 16px;
}

.social_link_slider {
  color: white;
  font-size: 30px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.social_link_slider:hover {
  color: rgb(181, 181, 181);
}

@media screen and (max-width: 1621px) {
  .slider_item_img {
    /* height: 200%;
    width: 220%; */
  }
}

@media screen and (max-width: 1300px) {
  .feature_detail {
    height: 450px;
  }
  .slider_item {
    gap: 10%;

    padding: 40px 120px;
  }
}

@media screen and (max-width: 1160px) {
  .product_description {
    background-color: rgb(10, 10, 10);
    margin: 0 -80px; /* From main container padding */
    margin-top: -5px;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 1160px) {
  .homepage_feature {
    height: 80vh;
    margin: 0 -80px;

    padding: 30px 80px;
  }
  .feature_detail {
    height: 400px;
    padding: 20px 10px;
  }
  .feature_detail_img {
    width: 110px;
    height: 140px;
  }

  .feature_detail_header {
    font-size: 28px;
  }
}

@media screen and (max-width: 1125px) {
  .homepage {
    padding: 70px 80px;
  }

  .homepage_team__container {
    margin: 0px -80px;
    padding: 40px 80px;
    margin-top: 90px;
  }
}

@media screen and (max-width: 1058px) {
  .slider_item {
    gap: 10%;

    padding: 40px 105px;
  }
  .slider_content__name {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .slider_content_title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .slider_content_description {
    line-height: 20px;

    font-size: 12px;
  }
}

@media screen and (max-width: 930px) {
  .homepage_header__imgClass {
    /* width: 100%; */
    height: 300px;
  }
  .slider_item {
    gap: 5%;

    padding: 40px 90px;
  }
  .slider_content__name {
    font-size: 20px;
    margin-bottom: 15px;
    height: 50px;
  }

  .slider_content_title {
    font-size: 14px;
    margin-bottom: 10px;
    height: 40px;
  }
}

@media screen and (max-width: 848px) {
  .slider_item {
    gap: 5%;

    padding: 40px 70px;
  }
  .slider_content__name {
    font-size: 18px;
    margin-bottom: 10px;
    height: 40px;
  }

  .slider_content_title {
    font-size: 14px;
    margin-bottom: 10px;
    height: 40px;
  }
}

@media screen and (max-width: 786px) {
  .homepage_feature {
    background-size: 100vw 120vh;

    padding: 30px 10px;
  }

  .feature_detail {
    height: 470px;
    /* padding: 20px 40px; */
  }

  .feature_detail_img {
    width: 80px;
    height: 110px;
  }

  .feature_detail_header {
    font-size: 26px;
  }

  .feature_detail_description {
    padding: 0 12px;
  }
  .homepage_header__imgClass {
    /* width: 100%; */
    height: 220px;
  }
}

/* Smaller Device */
@media screen and (max-width: 670px) {
  .homepage {
    padding: 40px;
  }
  .homepage_why__container {
    padding: 20px;
  }

  .homepage_header {
    gap: 10px;
  }
  .homepage_header__heading {
    font-size: 30px;

    margin-bottom: 28px;
  }

  .homepage_header__description {
    font-size: 16px;
    margin-bottom: 26px;
  }

  .homepage_header__button {
    padding: 6px 15px;
  }

  .homepage_header__imgClass {
    height: 180px;
  }

  .product_description {
    padding: 30px 40px;
    margin: 0 -40px;

    padding-bottom: 50px;
  }
  .homepage_feature {
    background-size: 100vw 220vh;

    height: 150vh;
    margin: 0 -40px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .feature_detail {
    padding: 20px 40px;
    margin-bottom: 0;
    height: 450px;
  }

  .feature_detail:nth-child(2n) {
    border-right: 0px;
  }
  .homepage_team__container {
    margin: 0px -40px;
    padding: 40px;
    margin-top: 50px;
    margin-bottom: -40px;
  }
}

@media screen and (max-width: 584px) {
  .slider_item {
    padding: 10px 60px;
  }
  .slider_content__name {
    font-size: 17px;
  }

  .slider_content_title {
    font-size: 12px;
    margin-bottom: 5px;
    height: 40px;
  }

  .homepage_header__heading {
    font-size: 32px;
  }
  .homepage_why__header {
    font-size: 40px;
  }
  .product_header {
    font-size: 50px;
  }

  .product_description__details_header {
    font-size: 24px;
  }
  .product_description__details_list {
    font-size: 15px;

    margin-bottom: 15px;
  }
}

@media screen and (max-width: 584px) {
  .slider_item {
    padding: 10px 48px;
    gap: 1%;
  }
}
@media screen and (max-width: 491px) {
  .homepage_product__container {
    margin-top: 50px;
  }
  .homepage_feature {
    background-image: none;
    background-color: black;
    height: 100%;
    grid-template-columns: 1fr;
  }

  .feature_detail {
    padding: 10px;
    margin-bottom: 0;
    height: 230px;
    border-right: none;
  }

  .slider_item {
    padding: 10px 45px;
    gap: 1%;
  }

  .slider_content_title {
    font-size: 12px;
    height: 50px;
  }
  .social_link_slider {
    font-size: 24px;
  }
  .homepage_header__button {
    padding: 6px 15px;
    font-size: 14px;
  }
  .product_description__details_btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 434px) {
  .homepage_header {
    gap: 3px;
  }
  .homepage_header__heading {
    font-size: 28px;
    margin-bottom: 15px;
  }

  .homepage_header__description {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .homepage_header__imgClass {
    height: 130px;
  }
  .slider_item {
    padding: 10px 35px;
  }
}

@media screen and (max-width: 420px) {
  .product_description {
    padding: 10px;
  }

  .homepage_why__header {
    margin-top: 100px;
    width: 100%;
    font-size: 26px;
  }

  .homepage_why__description {
    font-size: 16px;
    line-height: 30px;
  }
  .product_description__details_header {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .product_description__details_list {
    font-size: 13px;

    margin-bottom: 10px;
  }
  .product_description__details_btn {
    padding: 5px 10px;
    border-radius: 13px;
    font-size: 12px;
  }
  .product_description__img {
    width: 50%;
  }
  .product_description:nth-child(4) .product_description__img {
    margin-top: 30px;
    margin-right: 20px;
    width: 25%;
    height: 250px;
  }
  .product_header {
    font-size: 37px;
  }
}

@media screen and (max-width: 362px) {
  .homepage_why__container {
    gap: 30px;
    padding: 20px;
    padding-right: 10px;
  }

  .slider_item {
    padding: 10px 30px;
    gap: 1%;
  }
  .slider_content__name {
    font-size: 14px;
  }

  .slider_content_title {
    font-size: 10px;
    height: 50px;
  }
  .social_link_slider {
    color: white;
    font-size: 20px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 335px) {
  .homepage_header__imgClass {
    height: 90px;
  }
}

video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.service {
  /* background: url('./images/robot.jpg') center center/cover no-repeat; 
    */

  /*     
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain; */
}

.service > h1 {
  color: rgb(0, 0, 0);
  font-size: 100px;
  margin-top: center;
  align-items: top;
  font-family: "Muli", sans-serif;
}
.service > h2 {
  color: rgb(0, 0, 0);
  font-size: 50px;
  margin-top: center;
  align-items: top;
  font-family: "Muli", sans-serif;
}

.service > p {
  margin-top: 8px;
  font-size: 25px;
  font-family: "Muli", sans-serif;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
}
.service > p4 {
  margin-top: 8px;
  color: rgb(0, 0, 0);
  font-size: 25px;
  font-family: "Muli", sans-serif;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
}
.service > p1 {
  margin-top: 8px;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-family: "Muli", sans-serif;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .service > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .service > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .service > p {
    font-size: 15px;
  }
  .service > p1 {
    font-size: 10px;
  }
  .service > p4 {
    font-size: 5px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.arrow-container {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
}

.arrow-1 {
  width: 100px;
  height: 100px;
  background: #00bcd4;
  opacity: 0.5;
  border-radius: 50%;
  position: absolute;
}

.arrow-2 {
  width: 60px;
  height: 60px;
  background: #00bcd4;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
  display: table;
}

.arrow-2:before {
  width: 52px;
  height: 52px;
  content: "";
  border: 2px solid #006064;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.arrow-2 i.fa {
  font-size: 30px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #006064;
}

/* Custom Animate.css */

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.4, 0.4, 0.4);
    transform: scale3d(0.4, 0.4, 0.4);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.4, 0.4, 0.4);
    transform: scale3d(0.4, 0.4, 0.4);
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Saira", sans-serif;
}

.home {
  background-color: rgb(0, 0, 0);
  color: #fff;
}
.pa {
  color: #fff;
}
.homebox {
  background-color: #fff;
}

.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url("/src/images/img-2.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url("/public/images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url("/public/images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.logo_section {
  min-height: 90vh;
  background-color: #040505;
  padding: 25px 500px 90px 500px;
}

.layout {
  background-color: black;
  color: white;
  padding: 70px 100px;
  font-family: "Saira", sans-serif;
  min-height: 100vh;
}

/* Hamburger CSS */
#portal .container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* background-color: red; */
  right: 0;
  padding: 0;
  /* z-index: 0; */
  height: 100vh;
  width: 100vw;

  background: rgba(0, 0, 0, 0.7);
}
.container .modal {
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background: black;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;
  animation: animate 0.3s;
}

.modal li {
  list-style: none;
}

@keyframes animate {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
@media screen and (max-width: 900px) {
  .logo_section {
    padding: 50px 210px 90px 210px;
  }
}
@media screen and (max-width: 500px) {
  .logo_section {
    padding: 25px 50px 25px 50px;
  }
}

.navbar1 {
  background-color: black;
  height: 110px;
  padding: 30px 100px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
  font-size: 17px;
  position: sticky;
  top: 0;
  z-index: 999;
  font-family: "Saira", sans-serif;
  box-shadow: 0px 2px 5px #1e1e1e;
}

.hamburger_menu {
  color: white;
  width: 40px;
  height: 60px;
  z-index: 10;
}

.active-nav {
  border-bottom: #fff 2.5px solid;
  padding-bottom: 5px;
}

.navbar1-container1 {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbar1-container1 li {
  list-style-type: none;
}
.navbar-logo1 {
  color: #fff;
  justify-self: start;

  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav1-item1 {
  height: 90px;
}

.logo-height {
  height: 110px;
}

.nav1-links1 {
  color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 30px;
}

.nav1-links1:hover {
  border-bottom: #fff 1px solid;
  padding-bottom: 5px;
  transition: all 0.2s ease-out;
  color: white;
}

.fa-bars {
  color: #fff;
}

.nav1-links1-mobile {
  display: none;
}

.menu-icon1 {
  display: none;
}

@media screen and (max-width: 1158px) {
  .navbar1 {
    padding: 30px 50px;
    font-size: 16px;
    gap: 10px;
  }
  .logo-height {
    height: 80px;
  }
}
@media screen and (max-width: 960px) {
  /* Hamburger Start */
}

@media screen and (max-width: 960px) {
  .navbar1 {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }

  .nav1-menu1.active {
    background: #242222;
    font-weight: bold !important;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .menu-icon1 {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav1-links1-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav1-links1-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.footer_container {
  display: flex;
  background-color: black;
  box-shadow: 0px 2px 5px #1e1e1e;
  justify-content: space-between;
  padding: 50px 100px;
  color: white;
}

.logo_height {
  height: 150px;
  width: 250px;
}
.footer_logoSubheading,
.copyright {
  font-size: 18px;
  font-weight: 300;
  width: 450px;
  line-height: 25px;
  margin-bottom: 40px;
}

.leftSide {
  width: 80%;
  border-left: 1px solid #505050;
  margin-top: -50px;
  margin-bottom: -50px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.leftSide_first {
  display: flex;

  gap: 80px;
  padding-left: 60px;

  padding-right: 130px;
  padding-bottom: 25px;
}

.quick_links_header,
.reachus_header {
  font-size: 34px;
  color: #727272;
  margin-top: 16px;
  margin-bottom: 10px;
}

.reachus_mail {
  font-size: 19px;
}

.social_icon {
  padding: 0px 10px;
  margin-top: 40px;
  font-size: 70px;
  color: white;
}

.social_icon:hover {
  color: rgb(49, 49, 49);
}

.quick_links_container {
  flex: 1;
  padding-left: 60px;
}

.quick_links_header {
  font-size: 30px;
  margin-bottom: 20px;
}

.quick_links_list {
  list-style: none;

  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  row-gap: 10px;
  font-size: 18px;
}

.quick_links_list a {
  text-decoration: none !important;
}

.quick_links_list li {
  line-height: 12px;
  margin-left: -25px;
  color: white;

  size: 18px;
}

.quick_links_list li:hover {
  color: rgb(180, 180, 180);
}

.mobile_copyright {
  display: none;
}

/* ===================  BREAKPOINTS =================== */

/* For large devices */
@media screen and (max-width: 1300px) {
  .footer_container {
    padding: 50px 50px;
  }
  .leftSide_first {
    margin-right: -50px;
    padding-right: 50px;
  }
  .searchBar_container {
    margin: 45px 0px 45px 0px;
  }

  .leftSide_first {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .leftSide_first {
    padding-left: 60px;
    gap: 20px;
  }

  .social_icon {
    font-size: 60px;
    margin-top: -20px;
    margin-bottom: 10px;
    padding: 0px 5px;
  }
  .leftSide_first {
    margin-right: 0px;
    padding-right: 0px;
    border-bottom: 0;
  }
}

@media screen and (max-width: 999px) {
  .main_logo_container {
    width: 450px;
  }

  .footer_logoSubheading {
    width: 90%;
  }

  .searchBar {
    width: 75%;
  }

  .social_icon {
    font-size: 50px;

    margin-top: 20px;
  }
}
@media screen and (max-width: 850px) {
  .quick_links_list {
    font-size: 14px;
  }
  .social_icon {
    font-size: 40px;
    margin-bottom: -10px;
  }
} /* For medium devices */
@media screen and (max-width: 768px) {
  .searchBar {
    width: 70%;
  }
  .main_logo_container {
    width: 80%;
    margin-top: 0px;
  }
}

@media screen and (max-width: 633px) {
  .footer_container {
    flex-direction: column;
    padding: 20px 50px;
  }

  .copyright {
    display: none;
  }

  .leftSide {
    border-left: 0;
    margin-bottom: 0px;
  }

  .leftSide_first {
    padding-left: 0px;
    gap: 10px;
    margin-top: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid #4c835f;
  }

  .quick_links_container {
    padding-left: 0;
  }

  .reachus_header {
    font-size: 30px;
  }

  .quick_links_header {
    font-size: 28px;
  }
  .reachus_mail {
    font-size: 19px;
  }

  .social_icon {
    font-size: 45px;
  }
  .main_logo_container {
    width: 100%;
    border-bottom: 1px solid #4c835f;
  }

  .quick_links_container {
    display: flex;
    gap: 20px;
  }

  .quick_links_list {
    margin-top: 30px;
    font-size: 14px;
    row-gap: 10px;
  }

  .quick_links_list a {
    margin-right: -20px;
  }

  .quick_links_list li {
    line-height: 18px;
  }

  .mobile_copyright {
    text-align: center;
    display: block;
    margin-top: 20px;
    font-weight: 600;
  }
}
@media screen and (max-width: 576px) {
}

@media screen and (max-width: 420px) {
  .footer_container {
    flex-direction: column;
    padding: 30px;
  }
  .footer_logo {
    font-size: 25px;
  }
  .logo_height {
    height: 120px;
    width: 200px;
  }
  .footer_logoSubheading {
    font-size: 16px;
  }
  .searchBar {
    width: 80%;
  }
  .searchBar_container {
    margin: 25px 0px;
  }

  .reachus_header {
    margin-top: 50px;
    font-size: 27px;
  }
  .reachus_mail {
    font-size: 16px;
    margin-bottom: 70px;
  }
  .social_icon:first-child {
    margin-top: 25px;
  }

  .leftSide_first {
    gap: 55px;
    border-bottom: none;
    padding-bottom: 0px;
  }
  .quick_links_header {
    font-size: 22px;
    margin-right: 0px;
  }
  .quick_links_container {
    gap: 3px;
  }
  .quick_links_list {
    margin-top: 20px;
    font-size: 13px;
    gap: 10px;
    row-gap: 3px;
  }
  .quick_links_container {
    border-top: 1px solid #4c835f;
    margin-right: -60px;
  }
  .mobile_copyright {
    margin-top: 30px;
    margin-left: 25%;
    margin-bottom: -60px;
  }
}

/* For small devices */
@media screen and (max-width: 376px) {
  .quick_links_header {
    font-size: 22px;
    margin-right: -25px;
  }
}

@media screen and (max-width: 330px) {
  .leftSide_first {
    gap: 1px;
  }
}

.signup_layout {
  background-color: black;
  text-align: center;
  color: white;
  padding: 50px 30%;
  font-family: "Saira", sans-serif;
  min-height: 100vh;
}

.signup_header {
  font-weight: 600;
  font-size: 28px;
}

.login_redirect {
  font-family: "roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
}

.blue_text {
  color: #1e8cd9;
  cursor: pointer;
}

.blue_text:hover {
  color: #0065fd;
}

.btn {
  display: block;
  width: 100%;
  color: white;
  border-radius: 12px;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  padding: 4px;
  border: none !important;
}

.btn p {
  margin-bottom: 0;
  display: inline;
}

.icon {
  display: inline;
  margin-bottom: 3px;
  font-size: 20px;
}

.or {
  margin-top: 8px;
  margin-bottom: 5px;
  display: block;
  font-weight: 200;
}

.name_input__container {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 20px;
}

.name_input {
  background-color: #333232;
  color: #8e8f8f;
  padding: 5px 38px;
  width: 48%;
  border-radius: 7px;
  border: none !important;
}

.input_container {
  display: flex;
  margin-bottom: 20px;
}

.signup_input {
  background-color: #333232;
  color: #8e8f8f;
  padding: 5px 38px;
  width: 100%;
  border-radius: 7px;
  border: none !important;
}

.valid {
  color: green;
  margin-top: 10px;
  margin-left: -60px;
}

.hide {
  display: none;
}

.invalid {
  color: red;
  margin-top: 10px;
  margin-left: -10%;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

.pwdInfo {
  font-size: 15px;
  text-align: left;
  margin-top: -15px;
  margin-bottom: 5px;
  color: #8e8f8f;
}

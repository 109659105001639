body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Montserrat:wght@100&family=Nunito:wght@200&family=Roboto:ital,wght@1,100&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'IBM Plex Sans', sans-serif;
    
   }
    */

/*
  .navbar{
      background-color: #fff;
  }
  .navbar-brand{
      font-size: 1.9rem;
      color :#565387;
  }
  .navbar a {
      font-size: 1.3rem;
      text-transform: capitalize;
  }
  .menu_active {
      font-weight: bold;
      border-bottom: 1px solid #565387;
  }
 .brand-name{
    font-weight: bold;
    color: #34db4a; 
 } 
  .navbar a:hover {
      color: #3498db
  }

  @media(max-width:991px){
      .navbar-brand {
          font-size: 1.5rem;
      }
      .menu_active{
          font-weight:bold;
          border-bottom:none;

      }
  } */

#header {
  width: 100%;
  height: 80vh;
  margin-top: 70px;
}

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: #000000;
}

#header .brand-name {
  color: white;
  font-size: 38px;
}
#header h2 {
  color: #484848;
  font-size: 20px;
}
#header .btn-get-started {
  font-weight: 500px;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #34db34;
  border: 2px solid #34db34;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #34db34;
  color: #fff;
}

.header-img {
  text-align: right;
}

#header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.contact_div {
  width: 100%;
  height: 75vh;
}

footer p {
  padding: 0;
  margin: 0;
}

@media (max-width: 991px) {
  #header {
    height: 80vh;
    text-align: center;
  }

  #header .header-img {
    text-align: center;
  }
  #header .header-img img {
    width: 60%;
  }
}

/* video */

#video_start {
  height: 110vh;
  margin-bottom: -80px;
}

#video_src {
  /* margin-left: -100px; */
  height: 100%;
  width: 115%;
  margin: 0px -100px;
  object-fit: cover;
  z-index: 100;
  position: relative;
}

@media screen and (max-width: 1600px) {
  #video_src {
    /* margin-left: -100px; */

    width: 115%;
    object-fit: fill;
    /* margin: 0; */
  }
}

@media screen and (max-width: 1300px) {
  #video_src {
    margin: 0 -95px;
    width: 116%;
  }
}

@media screen and (max-width: 1170px) {
  #video_src {
    margin: 0 -75px;
    width: 116%;
  }
}

@media screen and (max-width: 860px) {
  #video_start {
    height: 70vh;
    /* margin-bottom: -80px; */
  }
  #video_src {
    margin: 0 -85px;
    width: 125%;
  }
}

@media (max-width: 768px) {
  #header {
    margin-top: 20px;
  }
  #header h1 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  #header h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  #header .header-img img {
    width: 70%;
  }
  #video_src {
    margin: 0 -40px;
    width: 110%;
  }
}

@media (max-width: 420px) {
  #video_start {
    display: none;
  }
  #video_src {
    display: none;
  }
  /* #video_start {
    height: 100vh;
  }
  #video_src {
    margin: 0 -40px;
    width: 125%;
  } */
}
